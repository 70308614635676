<!-- 班次编辑添加 -->
<template>
  <div class="classesManageAddEdit bgFFF">
    <el-form ref="form" :model="form" label-width="100px" :rules="rules">
      <el-form-item label="班次名称:" prop="scheduleName">
        <el-input
          :disabled="disabled"
          v-model="form.scheduleName"
          placeholder="请输入班次名称"
        >
		</el-input>
      </el-form-item>
	  <el-form-item label="所属运营商:" prop="operationId">
           <el-select v-model.trim="form.operationId" filterable size="15" :disabled="disabled">
				  <el-option label="全部" value></el-option>
				  <el-option
					:label="value.operationName"
					:value="value.operationId"
					:key="value.operationId"
					v-for="value in tenantList"
				  >
				  </el-option>
           </el-select>			
	  </el-form-item>	  
      <el-form-item label="上班时间前:" prop="beforeMin">
        <el-input
          :disabled="disabled"
          v-model.number="form.beforeMin"
          maxlength="6"
        >
		</el-input>
		<span class="txt_tip">分钟可打卡<span class="tipColor">（不能早于上班时间的180分钟）</span></span>
      </el-form-item>
      <el-form-item label="上班时间:" prop="onTime">
        <el-time-picker
          :disabled="disabled"
          v-model="form.onTime"
          format="HH:mm"
          value-format="HH:mm"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="任意时间点"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="下班时间:" prop="offTime">
        <el-time-picker
          :disabled="disabled"
          v-model="form.offTime"
          format="HH:mm"
          value-format="HH:mm"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="任意时间点"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="下班时间后:" prop="afterMin">
        <el-input
          :disabled="disabled"
          v-model.number="form.afterMin"
          maxlength="6"
        ></el-input
        ><span class="txt_tip"
          >分钟可打卡<span class="tipColor"
            >（不能晚于下班时间的180分钟）</span
          ></span
        >
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import { dateFormat } from "common/js/public";
export default {
  components: {},
  data() {
    return {
	  tenantList: [],	
      form: {
        scheduleName: "",
        beforeMin: "",
        onTime: "07:00",
        offTime: "19:00",
        afterMin: "",
		operationId: ""
      },
      rules: {
        scheduleName: [
          {
            required: true,
            message: "请输入班次名称",
            trigger: "blur",
          },
          { max: 10, message: "长度在10个字符以内", trigger: "blur" },
        ],
        beforeMin: [
          {
            required: true,
            message: "请输入上班时间前打卡时间",
            trigger: "blur",
          },
          { type: "number", message: "请输入整数" },
        ],
        onTime: [
          {
            required: true,
            message: "请选择上班时间",
            trigger: "change",
          },
        ],
        offTime: [
          {
            required: true,
            message: "请选择下班时间",
            trigger: "change",
          },
        ],
        afterMin: [
          {
            required: true,
            message: "请输入下班时间前打卡时间",
            trigger: "blur",
          },
          { type: "number", message: "请输入整数" },
        ],
      },
      url: "",
      data: {},
      disabled: true,
    };
  },
  // 方法集合
  methods: {
    getDetails() {
      this.$axios
        .get("/acb/2.0/pdaSchedule/queryById/" + this.form.scheduleId, {
          data: {
            d: "",
          },
        })
        .then((res) => {
          // console.log(res);
        });
    },
	
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(res => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      })
    },
	
    onSubmit() {
      this.$refs.form.validate((v) => {
        if (v) {
          this.$axios
            .post(
              this.url,
              {
                data: {
                  scheduleId: this.form.scheduleId || "",
                  scheduleName: this.form.scheduleName,
                  onTime: this.form.onTime,
                  beforeMin: this.form.beforeMin,
                  offTime: this.form.offTime,
                  afterMin: this.form.afterMin,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json;",
                },
              }
            )
            .then((res) => {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
                callback: (action) => {
                  this.$router.go(-1);
                },
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getTenantList();  
    let data = this.$route.query;
    if (JSON.stringify(data) !== "{}") {
      this.url = "/acb/2.0/pdaSchedule/update";
      this.form = data;
      this.getDetails();
    } else {
      this.url = "/acb/2.0/pdaSchedule/add";
    }
  },
  mounted() {},
};
</script>
<style lang="stylus" scoped>
//@import url(); 引入公共css类
.classesManageAddEdit
  .el-form
    .el-form-item
      .el-input
        width 220px
      .txt_tip
        color #606266
        margin-left 20px
        .tipColor
          color #AAAAAA
</style>
